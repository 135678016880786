import { PaginatedTable, Paginator } from "../../Components";
import { ActionInterface } from "../../Components/BasicTable";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { SMALL_PAGE_SIZE } from "../../constant";

import { getUserList } from "../../Services/Service";
import {
  Change_User_Password,
  Create_User,
  Edit_User,
} from "./../../RouteConstant";
import { onFailure } from "./../../util/helper";
import { DefaultProps } from "../../util/types";

const ManageUserPage = () => {
  const [data, setData] = React.useState({ list: [], total: 0 });
  const history = useHistory();
  const [currentPage, setPage] = useState(1);
  const onSuccess = (data: any) => {
    setData(data.data);
  };

  React.useEffect(() => {
    getUserList(onSuccess, onFailure, { populate: "roles_f=name",isRecent:true });
  }, []);

  const headings = [
    {
      key: "name",
      text: "Name",
    },
    {
      key: "email",
      text: "Email",
    },
    {
      key: "phone",
      text: "Phone",
    },
    {
      key: "roles",
      text: "Role",
    },
    {
      key: "status",
      text: "Status",
    },
  ];

  const pageChange = (page: any) => {
    getUserList(onSuccess, onFailure, { page: page });
    setPage(page);
  };

  const formatters = {
    roles: (data: any) => data?.map((role: any) => role.name).join(","),
  };

  const actionItems: ActionInterface[] = [
    {
      text: "Edit",
      trigger: (data: any) => history.push(Edit_User.replace(":id", data._id)),
    },
    {
      text: "Change Password",
      trigger: (data: any) =>
        history.push(Change_User_Password.replace(":id", data._id)),
    },
    {
      text: "Block",
      trigger: (data: any) => console.log(data),
    },
  ];
  return (
    <>
      <main className="app-content" id="app">
        <div className="app-title">
          <div>
            <h1>Manage User</h1>
          </div>
          <Button
            onClick={() => history.push(Create_User)}
            className="btn btn-success"
          >
            Add User
          </Button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <PaginatedTable
                  headings={headings}
                  data={data.list}
                  totalSize={data.total}
                  pageSize={SMALL_PAGE_SIZE}
                  // showActionDrawer={false}
                  formatters={formatters}
                  actionItems={actionItems}
                  renderPager={() => (
                    <Paginator
                      totalPages={Math.ceil(data.total / SMALL_PAGE_SIZE)}
                      onPageChange={(page) => pageChange(page)}
                      page={currentPage}
                    />
                  )}
                ></PaginatedTable>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default ManageUserPage;
